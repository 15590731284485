











import { AudioPlayEventBus } from '@/libs/EventBus';
import Vue from 'vue';

export default Vue.extend({
  components: {
  },
  props: {
    audioUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isAudioPlaying: false,
      audioSource: new Audio(),
    };
  },
  computed: {
  },
  mounted() {
    AudioPlayEventBus.$on('stop-all-audios', this.stopAudio);
  },
  methods: {
    playAudio(src: string) {
      AudioPlayEventBus.$emit('stop-all-audios');
      this.audioSource = new Audio(src);
      this.audioSource.play();
      this.isAudioPlaying = true;
    },
    stopAudio() {
      this.audioSource.pause();
      this.isAudioPlaying = false;
    },
  },
});
