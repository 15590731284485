




















































































































import Vue from 'vue';
import Auth from '@/libs/auth';
import QuizGroupApi from '@/apis/QuizGroupApi';
import { QuizGroupModel, QuizGroupPreviewModel } from '@/apis/models/QuizGroupModel';
import BackButton from '@/components/BackButton.vue';
import AudioPlayBtn from '@/components/quiz_group/AudioPlayBtn.vue';
import DifficultyMark from '@/components/quiz_maker/DifficultyMark.vue';
import ScoreEditor from '@/components/quiz_maker/ScoreEditor.vue';
import QuizApi from '@/apis/QuizApi';

export default Vue.extend({
  components: {
    BackButton,
    AudioPlayBtn,
    DifficultyMark,
    ScoreEditor,
  },
  data() {
    return {
      quizGroup: null as QuizGroupModel | null,
      isAudioPlaying: false,
      theAudio: new Audio(),
      totalScore: 0 as number | undefined,
      scoreEditor: false,
      imgHidden: false,
    };
  },
  computed: {
    isLoading(): boolean {
      return this.$store.state.isLoading;
    },
    workspaceId(): string {
      return this.$store.state.currentWorkspace.uuid;
    },
    currentQuizGroup(): QuizGroupPreviewModel {
      return this.$store.state.currentQuizGroup;
    },
    quizGroupId(): string {
      return this.$store.state.currentQuizGroup.uuid;
    },
    editPermission(): boolean {
      return this.$store.state.editPermission;
    },
  },
  async mounted() {
    Auth.loginCheck();
    this.$store.commit('updateLoading', true);
    try {
      this.quizGroup = await QuizGroupApi.retrieve(this.quizGroupId);
      console.log('quizGroup', this.quizGroup);
      // 幫助區
      this.quizGroup.quizList.forEach((quiz) => {
        if (quiz.answerSet[0] === '') {
          quiz.answerSet = [];
        }
      });
      // ------
      this.totalScore = this.quizGroup.totalScore;
    } finally {
      this.$store.commit('updateLoading', false);
    }
  },
  methods: {
    toQuizGroupMaker() {
      this.$router.push('/manager/quizgroup-maker');
    },
    calculateTotal() {
      let num = 0;
      if (this.quizGroup) {
        this.quizGroup.quizList.forEach(async (instance) => {
          num += Number(instance.score);
        });
      }
      this.totalScore = num;
    },
    async updateEditedScore() {
      this.$store.commit('updateLoading', true);
      this.scoreEditor = false;
      this.imgHidden = false;
      try {
        if (this.quizGroup) {
          const requests = [] as Promise<string>[];
          this.quizGroup.quizList.forEach(async (instance) => {
            const payload = {
              quizGroupId: this.quizGroupId,
              quizId: instance.uuid,
              score: instance.score,
            };
            const promise = QuizApi.setScore(payload);
            requests.push(promise);
          });
          await Promise.all(requests);
        }
      } finally {
        this.$store.commit('updateLoading', false);
      }
    },
    cencleEditedScore() {
      this.scoreEditor = false;
      this.imgHidden = false;
      window.location.reload();
    },
  },
});
