












import { QuizTemplateModel } from '@/apis/models/QuizModel';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  name: 'ScoreEditor',
  components: {
  },
  props: {
    score: {
      type: Number,
      required: true,
    },
    scoreEditor: {
      type: Boolean,
      required: true,
    },
    quiz: {
      type: Object as PropType<QuizTemplateModel>,
      required: true,
    },
  },
  data() {
    return {
      editedScore: this.score,
    };
  },
  computed: {
  },
  watch: {

  },
  methods: {
    calculateTotal() {
      this.quiz.score = Number(this.editedScore);
      this.$emit('calculateTotal');
    },
  },
});
